.padded {
    padding: var(--xs-spacing) !important;
    text-align: center;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    font-size: var(--d-font-size) !important;
}

.secondary {
    background-color: var(--secondary) !important;
    color: var(--primary) !important;
}

.primary {
    background-color: var(--primary) !important;
    color: var(--white) !important;
}
