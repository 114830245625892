.container {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    margin-left: 20px;
}

.btnStyles {
    background-color: transparent !important;
    color: var(--secondary) !important;
    box-shadow: none !important;
    height: 20px;
    font-size: var(--m-font-size) !important;
}

.icon {
    position: relative;
    top: -2px;
}

.viewAll {
    min-width: 110px !important;
    color: var(--secondary) !important;
    text-align: center !important;
}

.viewAll img {
    height: 20px;
    margin-bottom: 4px;
}

.border {

}
