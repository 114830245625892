.profileSection {
    width: 100%;
    background: var(--secondary);
}


.stats1 {
    width: 50%;
    text-align: center;

    @media (--desktop) {
        width: 30%;
    }
}

.edit {
    position: absolute;
    z-index: 999;
    right: var(--m-spacing);
}

.stats {
    margin: var(--s-spacing) 0 !important;
    width: 70%;
}

.opacity1 {
    opacity: 1;
}

.inputClass {
    background: var(--grey) !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    border: 0 !important;
}

.nestedInputClass {
    background: transparent !important;
}

.clickable {
    padding: 0 16px !important;
}

.full {
    width: 100%;
}

.follow {
    width: 30%;
    text-align: center;
}

.paper {
    width: 100%;
    height: 200px;
    position: relative;
}

.img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.root {
    flex-grow: 1;
    background: var(--secondary);
    box-shadow: none;
    color: var(--white);
}

.head {
    margin:var(--l-spacing);
    margin-left: 24px;
    padding: 24px 16px 0px 16px;

    @media (--phone) {
        margin-left: 0px;
    }
}

.name {
    font-size: var(--h4-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
}

.head1 {
    padding: 24px 16px 20px 16px;
    font-size: var(--h5-font-size);
    font-weight: var(--semi-bold);
}

.closeIcon {
    position: absolute;
    left: calc(50% - 28px);
    bottom: 8px;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    color: var(--white);
}

.pc {
    height: calc(100vh - 302px) !important;
}

.followButton {
    background-color: var(--secondary) !important;
    box-shadow: none !important;
    position: absolute !important;
    right: 0;
    bottom: 0;
}

.divider {
    padding-left: 5px;
    border-left: 1px solid #d4d4d4;
}

.primary2 {
    background: var(--primary2);
    background-color: var(--primary2);
}

.alignTop {
    margin-top: 4px;
}

.align {
    background-color: var(--secondary) !important;
    padding: 4px 12px !important;
    border-radius: 16px !important;
    color: var(--primary1) !important;
    font-size: var(--s-font-size);
    font-weight: var(--bold);
    text-transform: capitalize !important;
    height: 18px;
}

.bn {
    /* height: calc(100vh - 24px); */
    background: var(--primary5);
}

.header {
    display: none;
}

.cateCardStyles {
    margin-right: var(--m-spacing);
    max-width: 110px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.cateTitleStyle {
    margin-top: 8px;
    color: var(--black80);
    font-size: var(--m-font-size);
    line-height: 1;
    word-break: break-word;
    text-transform: capitalize;
    font-weight: var(--medium-bold);
}

.tags {
    padding-bottom: 0 !important;
}

.containerStyle {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 24px 16px !important;
    padding-top: var(--xxxs-spacing) !important;
    margin-left: var(--xxl-spacing);

    @media (--phone) {
        margin-left: 0;
    }
}

.center {
    justify-content: center;
}

.posts_text {
    display: flex;
    align-items: flex-end;
    font-size: var(--m-font-size);
    margin-left: 10px;
    text-transform: capitalize !important;
    color: var(--primary2);
}

.aboutUs {
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--black70);
    padding: 0 16px;
}

.actionSection {
    display: flex;
    flex-direction: column;

    @media (--desktop) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

.filled input {
    padding: 16px 12px !important;
    font-size: var(--d-font-size) !important;
}

.filled *:before,
.filled *:after {
    border: none !important;
}

.postTile {
    height: 100px !important;
    padding: 0 !important;
    background: var(--primary1);
    border-radius: 4px;
    margin: 1px;
    position: relative;

    @media (--desktop) {
        height: 200px !important;
    }
}

.postPreview {
    height: 100px !important;
    width: 100%;
    position: relative !important;
    transform: none !important;
    object-fit: cover;
    left: 0% !important;

    @media (--desktop) {
        height: 200px !important;
    }
}

.parent {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 100px));
    grid-column-gap: 2px;
    grid-row-gap: 2px;

    @media (--desktop) {
        grid-template-rows: repeat(6, minmax(0, 200px));
        grid-column-gap: 8px;
        grid-row-gap: 8px;
    }
}

.dp {
    margin-right: var(--xl-spacing);
    width: 70px;
    height: 70px;
    min-width: 70px;
    border-radius: var(--xxxxl-spacing);
}

.share {
    border: 0 !important;
    padding: 0 !important;
    font-size: var(--m-font-size) !important;
    color: var(--black50) !important;
    display: flex;
    align-items: center;
}

.shareText {
    padding-left: var(--s-spacing);
}

.rte {
    margin: 8px 0;
}

.rte p {
    margin: 0 !important;
}

.relative {
    position: relative;
    padding: 4px 38px !important;
    padding-top: 24px !important;
    display: flex;
    align-items: center;

    @media (--phone) {
        padding: 4px 16px !important;
        padding-top: 24px !important;
    }
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fs16 {
    font-size: var(--h5-font-size) !important;
    font-weight: var(--semi-bold);
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (--phone) {
        max-width: 20ch;
    }
}

.anchor {
    font-size: var(--m-font-size);
    color: var(--primary);
    text-decoration: none;
}

.hidden {
    display: none;
}

.tncPreview {
    color: var(--black70);
    font-size: var(--d-font-size);
    position: relative;
    padding: 0 var(--l-spacing);
    padding-bottom: var(--m-spacing);

    &::before {
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        background: var(--black50);
        display: block;
        left: 0;
        top: 4px;
    }
}

.tncPreview>p {
    margin: 0 !important;
}

.tncPreviewUl {
    padding: 0 var(--l-spacing);
    list-style-type: none;
}

.speed {
    color: var(--green);
}

.catalogContainer {
        margin: var(--xxxxxl-spacing);
        margin-top: 8px;
}

.catalogHeadContainer {
    margin: var(-l-spacing);
    padding-top: 40px;

    @media (--phone) {
        padding-top: 0;
    }
}

.singleCatalog {
    border: 1px solid var(--black10);
    border-radius: var(--xxxxl-spacing);
    height: 142px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 8rem;
    cursor: pointer;
}

.singleCatalogTitle {
    margin: var(--l-spacing) 0 var(--l-spacing) var(--l-spacing);
    color: var(--black80);
    font-size: var(--m-font-size);
    word-break: break-word;
    text-transform: capitalize;
    font-weight: var(--medium-bold);
}
