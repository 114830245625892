.tile {
    height: 238px !important;
    padding: 0 !important;
    position: relative;
    width: calc(50% - 8px);
    background: var(--secondary);
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;

    @media (--desktop) {
        width: calc(25% - 16px) !important;
        min-width: calc(25% - 16px) !important;
        margin-right: 16px;
    }
}

.height70 {
    height: 167px;
    position: relative;
}

.oos {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px;

    @media (--desktop) {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing);
    }
}

.desc {
    padding: 10px 6px 10px 6px;
    font-size: var(--d-font-size);
    color: var(--black);
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.price {
    padding: 0 6px;
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--medium-bold);
    font-weight: 500;
    width: calc(100% - 12px);
}

.primary2 {
    font-size: var(--s-font-size);
    color: var(--black60);
}

.green {
    font-size: var(--m-font-size);
    color: #32BA46;
}

.border10 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: contain;
}

.border1 {
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
}

.border2 {
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.share {
    position: absolute;
    right: 16px;
    top: calc(167px - 48px);
}

.decimal {
    font-size: var(--s-font-size) !important;
    font-weight: var(--medium-bold) !important;
}
