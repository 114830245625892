.container {
    margin-bottom: var(--m-spacing);
}

.imgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black05);
    position: relative;
    height: 162px;
    width:  162px;

    @media (--desktop) {
        height: 232px;
        width: 222px;
        /*z-index: -99999 !important*/
    }
}

.oos {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px;

    @media (--desktop) {
        border-radius: var(--border-radius);
        padding: var(--s-spacing) var(--xl-spacing);
    }
}

.img {
    width: 162px;
    height: 162px;
    object-fit: cover;

    @media (--desktop) {
        width: 224px;
        height: 232px;
    }
}

.click {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.priceContainer,
.bottomContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.large .bottomContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.large .imgContainer {
    height: 92vw;
    width: 92vw;

    @media (--desktop) {
        width: 300px;
        height: 317px;
    }
}

.large .img {
    max-width: 92vw;
    max-height: 92vw;
    width: auto;
    height: auto;
    object-fit: cover;

    @media (--desktop) {
        width: 300px;
        height: 317px;
    }
}

.name {
    margin-top: var(--m-spacing);
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.price,
.price2 {
    margin-bottom: var(--xs-spacing);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.price2 {
    margin-bottom: var(--s-spacing);
}

.amount,
.discount {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.amount span {
    font-size: var(--s-font-size);
}

.discount {
    font-size: var(--s-font-size);
}

.originalAmount {
    font-size: var(--s-font-size);
    color: var(--black50);
    text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.discount {
    color: var(--green);
}

.unit {
    color: var(--black50);
    text-transform: capitalize;
}

.share {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.large .share {
    position: absolute;
    right: 16px;
    top: calc(300px - 48px);
}



