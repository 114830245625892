span.show-more-less-clickable {
    cursor:pointer;
    text-decoration:underline;
}

span.show-more-less-clickable:hover{
    text-decoration:none;
}
.Button_padded__60f58 {
    padding: var(--xs-spacing) !important;
    text-align: center;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    font-size: var(--d-font-size) !important;
}

.Button_secondary__gf0Uw {
    background-color: var(--secondary) !important;
    color: var(--primary) !important;
}

.Button_primary__frHpN {
    background-color: var(--primary) !important;
    color: var(--white) !important;
}

.Image_border__Fx5Jv {
    border-radius: 8px;
    -o-object-fit: cover;
       object-fit: cover;
}

.Image_none___9lOn {
    display: none;
}
    
.Product_tile__7nAOS {
    height: 238px !important;
    padding: 0 !important;
    position: relative;
    width: calc(50% - 8px);
    background: var(--secondary);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer
}

@media screen and (min-width: 420px) {

.Product_tile__7nAOS {
        width: calc(25% - 16px) !important;
        min-width: calc(25% - 16px) !important;
        margin-right: 16px
}
    }

.Product_height70__uOG6E {
    height: 167px;
    position: relative;
}

.Product_oos__qukcH {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px
}

@media screen and (min-width: 420px) {

.Product_oos__qukcH {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing)
}
    }

.Product_desc__kKuAi {
    padding: 10px 6px 10px 6px;
    font-size: var(--d-font-size);
    color: var(--black);
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Product_price__JIA3b {
    padding: 0 6px;
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--medium-bold);
    font-weight: 500;
    width: calc(100% - 12px);
}

.Product_primary2__wnsmw {
    font-size: var(--s-font-size);
    color: var(--black60);
}

.Product_green___V8sX {
    font-size: var(--m-font-size);
    color: #32BA46;
}

.Product_border10__oaI7f {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -o-object-fit: contain;
       object-fit: contain;
}

.Product_border1__4rdJW {
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
}

.Product_border2__ff3Sy {
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.Product_share__pBLgw {
    position: absolute;
    right: 16px;
    top: calc(167px - 48px);
}

.Product_decimal__VIEWG {
    font-size: var(--s-font-size) !important;
    font-weight: var(--medium-bold) !important;
}

.Carousel_container__e5JuZ {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    margin-left: 20px;
}

.Carousel_btnStyles__6TA45 {
    background-color: transparent !important;
    color: var(--secondary) !important;
    box-shadow: none !important;
    height: 20px;
    font-size: var(--m-font-size) !important;
}

.Carousel_icon__bI0B3 {
    position: relative;
    top: -2px;
}

.Carousel_viewAll__aE1iq {
    min-width: 110px !important;
    color: var(--secondary) !important;
    text-align: center !important;
}

.Carousel_viewAll__aE1iq img {
    height: 20px;
    margin-bottom: 4px;
}

.Carousel_border__cBKXE {

}

.NoOrders_text__GS_qe {
    color: var(--black50);
    padding-top: 20px;
}


.Templates_cardTitle__ksZ7m {
    font-weight: var(--semi-bold);
    font-size: var(--h4-font-size);
    color: var(--secondary);
    padding-bottom: var(--s-spacing);
    text-align: center;
}

.Templates_cardDesc__VC_Xp {
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--secondary);
    text-align: center;
}

.Templates_card__lSyRR {
    height: 180px;
    min-width: 75%;
    width: 246px;
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: var(--m-spacing);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    padding: 0 var(--xl-spacing);
    position: relative;
    background-size: 100% 100%
}

@media screen and (min-width: 420px) {

.Templates_card__lSyRR {
        min-width: 320px;
        width: 320px;
        height: 180px
}
    }

.Templates_single__EBKp_ {
    display: flex;
    /* width: calc(100% - 32px); */
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 0 var(--l-spacing);
    padding-top: var(--xl-spacing)
}

.Templates_single__EBKp_:last-child {
        padding-bottom: var(--xl-spacing) !important;
    }

@media screen and (min-width: 420px) {

.Templates_single__EBKp_ {
        justify-content: center
}
    }

.ProductColors_outer__8iSRi {
    width: 20px;
    height: 20px;
    margin-right: 16px !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ProductColors_outline__pVIda {
    outline-offset: 2px;
    outline-style: solid !important;
}

.ProductColors_link__kywcM {
    font-size: var(--d-font-size);
    border-bottom: 1px solid;
    cursor: pointer;
}

.ProductTags_container__PQEmU {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ProductTags_button__yeRpH {
    border-radius: var(--border-radius);
    padding: var(--xs-spacing);
    margin: var(--xxs-spacing) var(--s-spacing) var(--xs-spacing) 0;
    cursor: pointer;
}
.GetPrice_decimal__248ah {
    font-size: var(--xxs-font-size) !important;
    font-weight: var(--medium-bold) !important;
}

.ProductCard_container__jtL_Z {
    margin-bottom: var(--m-spacing);
}

.ProductCard_imgContainer__ddI0N {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black05);
    position: relative;
    height: 162px;
    width:  162px
}

@media screen and (min-width: 420px) {

.ProductCard_imgContainer__ddI0N {
        height: 232px;
        width: 222px
        /*z-index: -99999 !important*/
}
    }

.ProductCard_oos__jaH4Y {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px
}

@media screen and (min-width: 420px) {

.ProductCard_oos__jaH4Y {
        border-radius: var(--border-radius);
        padding: var(--s-spacing) var(--xl-spacing)
}
    }

.ProductCard_img__lvMeu {
    width: 162px;
    height: 162px;
    -o-object-fit: cover;
       object-fit: cover
}

@media screen and (min-width: 420px) {

.ProductCard_img__lvMeu {
        width: 224px;
        height: 232px
}
    }

.ProductCard_click___qzpo {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.ProductCard_priceContainer___6G6R,
.ProductCard_bottomContainer__VVmz6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ProductCard_large___f74_ .ProductCard_bottomContainer__VVmz6 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ProductCard_large___f74_ .ProductCard_imgContainer__ddI0N {
    height: 92vw;
    width: 92vw
}

@media screen and (min-width: 420px) {

.ProductCard_large___f74_ .ProductCard_imgContainer__ddI0N {
        width: 300px;
        height: 317px
}
    }

.ProductCard_large___f74_ .ProductCard_img__lvMeu {
    max-width: 92vw;
    max-height: 92vw;
    width: auto;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover
}

@media screen and (min-width: 420px) {

.ProductCard_large___f74_ .ProductCard_img__lvMeu {
        width: 300px;
        height: 317px
}
    }

.ProductCard_name__c0hUg {
    margin-top: var(--m-spacing);
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.ProductCard_price__WkFBF,
.ProductCard_price2___pM0Y {
    margin-bottom: var(--xs-spacing);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.ProductCard_price2___pM0Y {
    margin-bottom: var(--s-spacing);
}

.ProductCard_amount___Hv_K,
.ProductCard_discount__biRYG {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.ProductCard_amount___Hv_K span {
    font-size: var(--s-font-size);
}

.ProductCard_discount__biRYG {
    font-size: var(--s-font-size);
}

.ProductCard_originalAmount__BbEU1 {
    font-size: var(--s-font-size);
    color: var(--black50);
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.ProductCard_discount__biRYG {
    color: var(--green);
}

.ProductCard_unit__jctNN {
    color: var(--black50);
    text-transform: capitalize;
}

.ProductCard_share__yKp_i {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.ProductCard_large___f74_ .ProductCard_share__yKp_i {
    position: absolute;
    right: 16px;
    top: calc(300px - 48px);
}




.common_viewAll__31JsP {
    padding: var(--l-spacing) var(--m-spacing);
 }
@media screen and (min-width: 420px) {
    .common_viewAll__31JsP {
        padding: 40px 40px;
 }
    }
.common_viewAll__31JsP .common_head__gaHhc {
        margin: var(--l-spacing) 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
.common_viewAll__31JsP .common_name__uBZOs {
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        color: var(--black);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB {
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB span {
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP .common_footer__MMjiX {
        margin: var(--l-spacing) 0;
        font-weight: var(--semi-bold);
        text-align: center;
    }
.common_viewAll__31JsP .common_footer__MMjiX span {
            font-size: var(--d-font-size);
            color: var(--primary);
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP {
 
 
    border-bottom: 1px solid var(--black15);
    padding-bottom: var(--m-spacing);
}
 

.Skeleton_container__Pu6xx {
    padding: var(--s-spacing) var(--m-spacing);
}

.Skeleton_main__2A792 {

}

.Skeleton_flashCard__QhXos {

}

.Skeleton_first__m_3FN {

}

.Skeleton_second__UakWt {

}

.DisablePopup_dialog__N9d4w{
    /* width: 586px !important; */
    height: 363px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important
}

@media screen and (max-width: 419px) {

.DisablePopup_dialog__N9d4w{
        font-size: var(--m-font-size);
        width: 100% !important;
        height: 11rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important
}
     }

.DisablePopup_image__dVuDn{
    text-align: center;
}

.DisablePopup_img__eoI9z{

    height:  76px;
    width:  76px
}

@media screen and (max-width: 419px) {

.DisablePopup_img__eoI9z{
        height:  42px !important;
        width:  42px !important
}
     }

.DisablePopup_content__JafNB{
    /* height: 87px; */
    margin-top: 28px

}

@media screen and (max-width: 419px) {

.DisablePopup_content__JafNB{
        margin-top: 18px

} 
    }

.DisablePopup_wrnText__2k7io{

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-left:20%;
    padding-right:20%;
    color: #212121
}

@media screen and (max-width: 419px) {

.DisablePopup_wrnText__2k7io{
        font-size: 12px !important;
        line-height: 16px !important
}
     }
.store_profileSection__TFIPG {
    width: 100%;
    background: var(--secondary);
}


.store_stats1__R1IRC {
    width: 50%;
    text-align: center
}


@media screen and (min-width: 420px) {


.store_stats1__R1IRC {
        width: 30%
}
    }

.store_edit__mRFSh {
    position: absolute;
    z-index: 999;
    right: var(--m-spacing);
}

.store_stats__DPJpW {
    margin: var(--s-spacing) 0 !important;
    width: 70%;
}

.store_opacity1__X_Z_Q {
    opacity: 1;
}

.store_inputClass__ZguVq {
    background: var(--grey) !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    border: 0 !important;
}

.store_nestedInputClass__rK1_M {
    background: transparent !important;
}

.store_clickable__Qw7_X {
    padding: 0 16px !important;
}

.store_full__6IRpQ {
    width: 100%;
}

.store_follow__X7ly4 {
    width: 30%;
    text-align: center;
}

.store_paper__jPufo {
    width: 100%;
    height: 200px;
    position: relative;
}

.store_img__ZcklI {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
}

.store_root__2N1P6 {
    flex-grow: 1;
    background: var(--secondary);
    box-shadow: none;
    color: var(--white);
}

.store_head__g_hCI {
    margin:var(--l-spacing);
    margin-left: 24px;
    padding: 24px 16px 0px 16px
}

@media screen and (max-width: 419px) {

.store_head__g_hCI {
        margin-left: 0px
}
    }

.store_name__mcTrx {
    font-size: var(--h4-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
}

.store_head1__C98vU {
    padding: 24px 16px 20px 16px;
    font-size: var(--h5-font-size);
    font-weight: var(--semi-bold);
}

.store_closeIcon__VXRz3 {
    position: absolute;
    left: calc(50% - 28px);
    bottom: 8px;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    color: var(--white);
}

.store_pc__ciA0q {
    height: calc(100vh - 302px) !important;
}

.store_followButton__ypb0l {
    background-color: var(--secondary) !important;
    box-shadow: none !important;
    position: absolute !important;
    right: 0;
    bottom: 0;
}

.store_divider__wZSEl {
    padding-left: 5px;
    border-left: 1px solid #d4d4d4;
}

.store_primary2__tb_ud {
    background: var(--primary2);
    background-color: var(--primary2);
}

.store_alignTop__lEzSn {
    margin-top: 4px;
}

.store_align__1OnqB {
    background-color: var(--secondary) !important;
    padding: 4px 12px !important;
    border-radius: 16px !important;
    color: var(--primary1) !important;
    font-size: var(--s-font-size);
    font-weight: var(--bold);
    text-transform: capitalize !important;
    height: 18px;
}

.store_bn__s5goT {
    /* height: calc(100vh - 24px); */
    background: var(--primary5);
}

.store_header__ACFmj {
    display: none;
}

.store_cateCardStyles___Dkne {
    margin-right: var(--m-spacing);
    max-width: 110px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.store_cateTitleStyle__BApT7 {
    margin-top: 8px;
    color: var(--black80);
    font-size: var(--m-font-size);
    line-height: 1;
    word-break: break-word;
    text-transform: capitalize;
    font-weight: var(--medium-bold);
}

.store_tags__13lhm {
    padding-bottom: 0 !important;
}

.store_containerStyle__yANRr {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 24px 16px !important;
    padding-top: var(--xxxs-spacing) !important;
    margin-left: var(--xxl-spacing)
}

@media screen and (max-width: 419px) {

.store_containerStyle__yANRr {
        margin-left: 0
}
    }

.store_center__PMZ3f {
    justify-content: center;
}

.store_posts_text__v_IRu {
    display: flex;
    align-items: flex-end;
    font-size: var(--m-font-size);
    margin-left: 10px;
    text-transform: capitalize !important;
    color: var(--primary2);
}

.store_aboutUs__bbRV_ {
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--black70);
    padding: 0 16px;
}

.store_actionSection__fQ7WN {
    display: flex;
    flex-direction: column
}

@media screen and (min-width: 420px) {

.store_actionSection__fQ7WN {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end
}
    }

.store_filled__Vp4O3 input {
    padding: 16px 12px !important;
    font-size: var(--d-font-size) !important;
}

.store_filled__Vp4O3 *:before,
.store_filled__Vp4O3 *:after {
    border: none !important;
}

.store_postTile__wkX_h {
    height: 100px !important;
    padding: 0 !important;
    background: var(--primary1);
    border-radius: 4px;
    margin: 1px;
    position: relative
}

@media screen and (min-width: 420px) {

.store_postTile__wkX_h {
        height: 200px !important
}
    }

.store_postPreview__DqqrN {
    height: 100px !important;
    width: 100%;
    position: relative !important;
    transform: none !important;
    -o-object-fit: cover;
       object-fit: cover;
    left: 0% !important
}

@media screen and (min-width: 420px) {

.store_postPreview__DqqrN {
        height: 200px !important
}
    }

.store_parent__c1lgl {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 100px));
    grid-column-gap: 2px;
    grid-row-gap: 2px
}

@media screen and (min-width: 420px) {

.store_parent__c1lgl {
        grid-template-rows: repeat(6, minmax(0, 200px));
        grid-column-gap: 8px;
        grid-row-gap: 8px
}
    }

.store_dp__T9OEW {
    margin-right: var(--xl-spacing);
    width: 70px;
    height: 70px;
    min-width: 70px;
    border-radius: var(--xxxxl-spacing);
}

.store_share__2o6_k {
    border: 0 !important;
    padding: 0 !important;
    font-size: var(--m-font-size) !important;
    color: var(--black50) !important;
    display: flex;
    align-items: center;
}

.store_shareText__dmXQW {
    padding-left: var(--s-spacing);
}

.store_rte__dADrO {
    margin: 8px 0;
}

.store_rte__dADrO p {
    margin: 0 !important;
}

.store_relative__h9M9R {
    position: relative;
    padding: 4px 38px !important;
    padding-top: 24px !important;
    display: flex;
    align-items: center
}

@media screen and (max-width: 419px) {

.store_relative__h9M9R {
        padding: 4px 16px !important;
        padding-top: 24px !important
}
    }

.store_flex__mHsx_ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.store_fs16__Inf0y {
    font-size: var(--h5-font-size) !important;
    font-weight: var(--semi-bold);
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media screen and (max-width: 419px) {

.store_fs16__Inf0y {
        max-width: 20ch
}
    }

.store_anchor__y4WNn {
    font-size: var(--m-font-size);
    color: var(--primary);
    text-decoration: none;
}

.store_hidden__T11Wv {
    display: none;
}

.store_tncPreview__5o_fG {
    color: var(--black70);
    font-size: var(--d-font-size);
    position: relative;
    padding: 0 var(--l-spacing);
    padding-bottom: var(--m-spacing)
}

.store_tncPreview__5o_fG::before {
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        background: var(--black50);
        display: block;
        left: 0;
        top: 4px;
    }

.store_tncPreview__5o_fG>p {
    margin: 0 !important;
}

.store_tncPreviewUl__GMOSM {
    padding: 0 var(--l-spacing);
    list-style-type: none;
}

.store_speed__fmdQ6 {
    color: var(--green);
}

.store_catalogContainer__1jFa4 {
        margin: var(--xxxxxl-spacing);
        margin-top: 8px;
}

.store_catalogHeadContainer__nUi_d {
    margin: var(-l-spacing);
    padding-top: 40px
}

@media screen and (max-width: 419px) {

.store_catalogHeadContainer__nUi_d {
        padding-top: 0
}
    }

.store_singleCatalog__dSrWS {
    border: 1px solid var(--black10);
    border-radius: var(--xxxxl-spacing);
    height: 142px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 8rem;
    cursor: pointer;
}

.store_singleCatalogTitle__YVLM2 {
    margin: var(--l-spacing) 0 var(--l-spacing) var(--l-spacing);
    color: var(--black80);
    font-size: var(--m-font-size);
    word-break: break-word;
    text-transform: capitalize;
    font-weight: var(--medium-bold);
}

