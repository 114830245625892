
.cardTitle {
    font-weight: var(--semi-bold);
    font-size: var(--h4-font-size);
    color: var(--secondary);
    padding-bottom: var(--s-spacing);
    text-align: center;
}

.cardDesc {
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--secondary);
    text-align: center;
}

.card {
    height: 180px;
    min-width: 75%;
    width: 246px;
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: var(--m-spacing);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    padding: 0 var(--xl-spacing);
    position: relative;
    background-size: 100% 100%;

    @media (--desktop) {
        min-width: 320px;
        width: 320px;
        height: 180px;
    }
}

.single {
    display: flex;
    /* width: calc(100% - 32px); */
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 0 var(--l-spacing);
    padding-top: var(--xl-spacing);
    &:last-child {
        padding-bottom: var(--xl-spacing) !important;
    }
    @media (--desktop) {
        justify-content: center;
    }
}
